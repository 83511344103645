@use 'styles/_variables.scss' as *;
.navItem {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: calc($l-screen + 1px)) {
    font-size: $small-font-size;
    border-top: 1px solid $pale-gray-4;

    &:first-child {
      border-top: none;
    }
  }

  &:hover,
  &.active .title {
    text-decoration: none;
    color: $indigo;
  }

  .title {
    color: $almost-black-80;
    cursor: pointer;
    display: flex;

    @media (max-width: $l-screen) {
      justify-content: space-between;
    }

    svg {
      display: inline-block;

      &.sectionIcon {
        transition: 0.3s;

        @media (max-width: calc($l-screen + 1px)) {
          transform: rotateX(0deg);
        }

        &.active {
          @media (max-width: calc($l-screen + 1px)) {
            transform: rotateX(180deg);
          }

          & > * {
            fill: $indigo;
          }
        }
      }
    }
  }

  &Main .title {
    font-family: $brand-font-family;
    font-weight: bold;
    font-size: $base-font-size;
    color: $almost-black-80;
    margin: $global-margin;
    margin-bottom: 0;
    display: flex;
    padding-bottom: 0.75 * $global-padding;
    line-height: $base-font-size * 1.5;

    @media (min-width: $l-screen) {
      margin: 4px 0;
      padding: $global-padding;
      font-size: 0.875 * $base-font-size;
    }

    a,
    span {
      color: $almost-black-80;
    }
    @media (pointer: fine) {
      &:hover {
        text-decoration: none;

        a,
        span {
          color: $indigo;
        }

        @media (min-width: $m-big-screen) {
          svg {
            transform: rotate(-180deg);
          }
        }
      }
    }
    &.active {
      text-decoration: none;

      a,
      span {
        color: $indigo;
      }

      @media (min-width: $m-big-screen) {
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    @media (min-width: $l-screen) {
      font-size: $base-font-size;
    }

    svg > * {
      fill: $dark-gray-4;
    }
  }
}

@media (max-width: calc($l-screen + 1px)) {
  .title {
    margin: 0 !important;

    &:has(a) {
      padding: 0 !important;
    }

    &:not(&:has(a)) {
      padding: $global-padding;
      padding-bottom: 0.75 * $global-padding;
    }

    a {
      padding: $global-padding $global-padding 0.75 * $global-padding $global-padding;
      width: 100%;
    }

    svg {
      margin: $global-padding $global-padding 0.75 * $global-padding;
    }
  }
  .dropdownWrapper {
    display: none;
    flex-direction: column;
    position: relative;
    background-color: $pale-gray-1;

    &.active {
      display: flex;
      border-top: 1px solid $pale-gray-4;
    }

    .backgroundShadow {
      display: none;
    }
    .background {
      display: flex;

      .maxWidthContainer {
        margin: 0;
        padding: 0;
        width: 100%;

        .contentWrapper {
          .left {
            display: flex;
            flex-direction: column;
            gap: 2.25 * $global-margin;
            padding: 1.5 * $global-padding;

            div[class^="LinkCollection_"] {
              padding: 0;
            }
            .additionalContentLinksWrapper {
              gap: 1.25 * $global-margin;
            }
          }
          .right {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: calc($l-screen + 1px)) {
  .dropdownWrapper {
    position: fixed;
    top: calc(var(--header-height, 98px) - 1px);
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    padding-top: 1px;
    visibility: hidden;
    opacity: 0;
    transition: 0.1s;

    &.active {
      display: block;
      visibility: visible;
      opacity: 1;
    }

    .backgroundShadow {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #00000033;
      z-index: 0;
    }

    .background {
      position: relative;
      background: linear-gradient(to right, $white 75%, $pale-gray-1 25%);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      z-index: 1;
      top: -0.5px;
    }

    .maxWidthContainer {
      display: block;
      width: 100%;
      max-width: 1100px;
      box-sizing: border-box;

      @media (min-width: 1200px) {
        padding-left: 0;
      }

      .contentWrapper {
        display: grid;
        padding-inline: 0;
        box-sizing: border-box;
        grid-template-columns: 1fr;

        @media (max-width: $xl-screen) {
          max-height: 90vh;
          overflow-y: auto;
        }

        @media (min-width: $l-screen) {
          $FeaturedContent-width: 256px;
          grid-template-columns: 1fr $FeaturedContent-width;
        }

        & > * {
          box-sizing: border-box;
        }

        .left {
          padding: 1.5 * $global-padding 0 2 * $global-padding;
          display: flex;
          flex-direction: row;
          gap: $global-margin;

          @media (min-width: $l-screen) {
            padding: 1.5 * $global-padding 2 * $global-padding 2 * $global-padding 0;
          }

          .additionalContentLinksWrapper:not(*:has(svg)) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            height: auto !important;
            width: 100%;

            &.oneColumn {
              flex-direction: column;
              a > div,
              span > div {
                min-width: 130px;
              }
            }

            a,
            span {
              width: auto !important;
              flex-basis: calc(calc(100% - 0.5 * $global-margin) / 2);
              flex-grow: 1;
              flex-shrink: 1;
            }
          }
        }

        .right {
          width: 100%;
          background-color: $pale-gray-1;
          box-shadow: 0 0 0 85vw $pale-gray-1;
          clip-path: inset(0 -50vw 0 0);
          padding: 0 !important;
          position: relative;
          left: -2.5 * $global-margin;

          @media (min-width: $l-screen) {
            padding: 1.5 * $global-padding 0 !important;
            width: min-content;
            left: unset;
          }
        }
      }
    }
  }
}
